import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CMSImage } from '../../image';

import styles from './PeopleGrid.module.scss';

const PeopleGrid = ({ data, isMobile }) => (
  <div className={styles.wrapper}>
    {data && data.map(({
      name, title, slug, thumbnail, altThumbnail,
    }) => (
      <div
        className={classNames(styles.personWrapper, { [styles.noPointer]: !slug })}
        key={name}
      >
        <div className={styles.frame}>
          <Link to={`/culture/${slug}`}>
            {altThumbnail && !isMobile && (
            <CMSImage
              {...altThumbnail}
              lazy
              className={classNames(styles.altThumbnail, {
                [styles.mobileThumbnail]: isMobile,
              })}
            />
            )}
            {(slug || !isMobile) && (
            <CMSImage
              {...thumbnail}
              lazy
              className={classNames(styles.thumbnail, {
                [styles.mobileThumbnail]: isMobile,
              })}
            />
            )}
          </Link>
        </div>
        <h3 className={styles.h3}>{name}</h3>
        <h4 className={styles.h4}>{title}</h4>
      </div>
    ))}
  </div>
);

PeopleGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    thumbnail: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    altThumbnail: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  })).isRequired,
  isMobile: PropTypes.bool,
};

PeopleGrid.defaultProps = {
  isMobile: false,
};

export default PeopleGrid;
