import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';

import { ExpertiseDetail } from '../../components/Expertise';
import BackButton from '../../components/BackButton';
import MetaData from '../../components/MetaData';

const ExpertiseCategory = ({
  data,
}) => {
  const { slug } = useParams();
  const [selectedData, set] = useState(null);

  useEffect(() => {
    if (slug && data) {
      set(data.categories.find(item => item.slug === slug));
    }
  }, [slug, data]);

  return (
    <React.Fragment>
      {selectedData && (
        <>
          <MetaData data={selectedData.MetaData} />
          <BackButton url="/expertise" />
          <ExpertiseDetail data={selectedData} />
        </>
      )}
    </React.Fragment>
  );
};

ExpertiseCategory.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf().isRequired,
  }).isRequired,
};

export default ExpertiseCategory;
