/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Video from './Elements/Video';
import TextLayer from './Elements/TextLayer';
import styles from '../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';

const VideoBlock = ({ data }) => {
  const textLayer = data.Text;
  return (
    <div className={[styles.projectBlock, styles.projectBlockVideo].join(' ')}>
      <Video vimeoId={data.Video.VideoId} responsive />
      <div className={styles.projectBackground}>
        <img src={data.Video.Poster.url} alt={data.Video.Poster.alt} />
        { textLayer && textLayer.ShowFill && <div className={styles.blockFill} /> }
      </div>
      <TextLayer {...textLayer} />
    </div>
  );
};

VideoBlock.propTypes = {
  data: PropTypes.shape({
    Video: PropTypes.shape({
      Poster: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      VideoId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default VideoBlock;
