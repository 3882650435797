/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DesktopCard from './DestopCard/Card';
import { CardIndexProvider } from './CardIndexContext';
import MetaData from '../../MetaData';
import styles from './Landing.module.scss';

const Landing = ({ data, device: { isMobile, width, height } }) => (
  <>
    <MetaData data={data.MetaData} />
    <div
      className={classNames(styles.wrapper, { [styles.mobile]: isMobile })}
    >
      <div className={styles.backgroudGraphic} />
      <div className={styles.cardWrapper}>
        <CardIndexProvider>
          {data.categories.map((item, index) => (
            <DesktopCard
              data={item}
              index={index}
              key={item.slug}
              width={width}
              height={height}
            />
          ))}
        </CardIndexProvider>
      </div>
    </div>
  </>
);

Landing.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf({}).isRequired,
    MetaData: MetaData.propTypes,
  }).isRequired,
  device: PropTypes.shape({
    isMobile: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default Landing;
