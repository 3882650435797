import parseCMSImageRecursive from '../../../../utils/parseCMSImageRecursive';
import { getByTypeAndSlug } from '../../../../api/page';

export default dispatch => async ({ slug }) => {
  const { data } = await getByTypeAndSlug({ type: 'home' });

  dispatch.page.setState({
    data: { slug, MetaData: data.MetaData, slides: parseCMSImageRecursive(data.Slides) },
  });
};
