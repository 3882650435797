/* eslint-disable no-console */
import { client } from '../constants';

const getByTypeAndSlug = async ({ slug, type }) => {
  const returnObject = {};
  try {
    const { data } = await client.get(`/${type}${slug ? `?slug=${slug}` : ''}`);
    returnObject.data = slug ? data[0] : data;
  } catch (error) {
    // TODO handle error
    console.log(error);
  }

  return returnObject;
};

export default getByTypeAndSlug;
