import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FoilText.module.scss';

const FoilText = ({ text, className }) => (
  <div className={classNames(styles.text, className)}>
    {text}
  </div>
);

FoilText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default FoilText;
