/* eslint-disable max-len */

import React from 'react';
import { useRematchDispatch, usePageData } from '../../hooks';
import SlideContainer from '../../components/Home/SlideContainer';
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';
import MetaData from '../../components/MetaData';

const Home = () => {
  const { getHomeData } = useRematchDispatch(dispatch => dispatch.page);
  const { data, loaded } = usePageData({
    rematchDispatch: getHomeData,
    slug: 'test',
    keys: ['slug', 'slides'],
  });

  const aboutSlide = { id: 'about', type: 'about', data: {} };
  const slides = !loaded ? [] : [aboutSlide].concat(data.slides.map(slide => (
    { id: slide.id, type: 'slide', data: slide }
  )));

  return (
    <>
      {loaded && (
        <>
          <MetaData data={data.MetaData} />
          <SlideContainer slides={slides} />
          <GlobalFooter />
        </>
      )}
    </>
  );
};

export default Home;
