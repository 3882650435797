/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { useRematchDispatch, usePageData } from '../../hooks';

import ExpertiseCategory from './ExpertiseCategory';
import { ExpertiseLanding } from '../../components/Expertise';
import MetaData from '../../components/MetaData';

import pageStyles from '../page.module.css';

const ExpertiseContainer = () => {
  const { path } = useRouteMatch();
  const device = useSelector(state => state.device);
  const { getExpertiseData } = useRematchDispatch(dispatch => dispatch.page);
  const { data, loaded } = usePageData({
    rematchDispatch: getExpertiseData,
    keys: ['categories', 'MetaData'],
    params: { isMobile: device.isMobile },
  });

  return (
    <div
      className={pageStyles.wrapper}
    >
      {loaded && (
        <Switch>
          <Route exact path={path}>
            <ExpertiseLanding data={data} device={device} />
          </Route>
          <Route path={`${path}/:slug`}>
            <ExpertiseCategory data={data} />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default ExpertiseContainer;
