import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Keyframes, animated } from 'react-spring/renderprops';
import { Link, useRouteMatch } from 'react-router-dom';

import FoilText from '../../../FoilText/FoilText';
import { CardIndexContext } from '../CardIndexContext';

import styles from './Card.module.scss';

const initialCardStyle = {
  width: 459,
  height: 642,
  transform: 'scale(0.45)',
  position: 'absolute',
  boxShadow: '-16px 14px 7px rgba(0, 0, 0, 0.5)',
  borderRadius: 20,
  zIndex: 1,
};

const initialCardStyles = [
  {
    ...initialCardStyle,
    top: 135,
    left: -44,
    transform: `${initialCardStyle.transform} rotate(-40deg)`,
  },
  {
    ...initialCardStyle,
    top: 49,
    left: 106,
    transform: `${initialCardStyle.transform}  rotate(-16.5deg)`,
  },
  {
    ...initialCardStyle,
    top: 6,
    left: 275,
    transform: `${initialCardStyle.transform}  rotate(-3.5deg)`,
  },
  {
    ...initialCardStyle,
    top: -5,
    left: 455,
    transform: `${initialCardStyle.transform}  rotate(3.5deg)`,
  },
  {
    ...initialCardStyle,
    top: 20,
    left: 620,
    transform: `${initialCardStyle.transform}  rotate(21deg)`,
  },
];

const items = [name => (<FoilText className={styles.cta} text={`View ${name} >`} />)];

const Card = ({
  data, index, width, height,
}) => {
  const [state, setState] = useState('unselected');
  const [selectedIndex, setSelectedIndex] = useContext(CardIndexContext);
  const initialStyle = initialCardStyles[index];
  const { path } = useRouteMatch();
  const selectedStyle = [
    {
      ...initialStyle,
      left: initialStyle.left - 100,
      zIndex: 2,
    },
    {
      ...initialStyle,
      left: width / 2 - initialCardStyle.width / 2 - (width - 1000) / 2,
      top: (height / 2 - initialCardStyle.height / 2) - (height - initialCardStyle.height) / 2 + 25,
      transform: 'scale(1) rotate(0deg)',
      boxShadow: '-16px 14px 30px rgba(0, 0, 0, 0.5)',
      zIndex: 2,
      cursor: 'default',
    },
  ];

  const selected = index === 4
    ? { from: initialStyle, to: selectedStyle[1] }
    : [{
      from: initialStyle,
      to: selectedStyle[0],
    },
    {
      from: selectedStyle[0],
      to: selectedStyle[1],
    }];

  const reverse = index === 4
    ? { from: selectedStyle[1], to: initialStyle }
    : [{
      from: selectedStyle[1],
      to: {
        ...selectedStyle[0],
        zIndex: 1,
      },
    },
    {
      from: {
        ...selectedStyle[1],
        zIndex: 1,
      },
      to: initialStyle,
    }];

  const Animated = Keyframes.Spring({
    unselected: initialStyle,
    selected,
    reverse,
  });

  const CTA = Keyframes.Trail({
    unselected: { opacity: 0 },
    selected: { from: { opacity: 0 }, opacity: 1, delay: 1500 },
    reverse: { opacity: 0 },
  });

  const onClick = () => {
    if (selectedIndex === null || selectedIndex === index) {
      setState(state !== 'selected' ? 'selected' : 'reverse');
    }
  };

  const onRest = () => {
    if (state === 'reverse') {
      setState('unselected');
      setSelectedIndex(null);
    }
  };

  useEffect(() => {
    if (state === 'selected') {
      setSelectedIndex(index);
    }
  }, [state]);

  const namesOfClasses = classNames(
    styles.wrapper,
    { [styles.isOpen]: state === 'selected' },
  );

  return (
    <Animated state={state} onRest={() => onRest()}>
      {props => (
        <animated.div
          className={namesOfClasses}
          style={props}
          onClick={() => onClick()}
        >
          <div className={styles.content}>
            <hr className={styles.hr} />
            <FoilText className={styles.header} text={data.name} />
            <FoilText
              className={styles.copy}
              text={data.cardText}
            />
            <hr className={styles.hr} />
            <CTA
              state={state}
              items={items}
            >
              {item => ({ ...rest }) => (
                <animated.div
                  style={rest}
                >
                  <Link className={styles.cardCTA} to={`${path}/${data.slug}`} style={{ textDecoration: 'none' }}>
                    {item(data.name)}
                  </Link>
                  <div className={styles.cardCloseButton}>
                    Close
                  </div>
                </animated.div>
              )}
            </CTA>
          </div>
        </animated.div>
      )}
    </Animated>
  );
};

Card.propTypes = {
  data: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Card;
