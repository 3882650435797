import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import useRematchDispatch from './useRematchDispatch';

const usePageViews = () => {
  const location = useLocation();
  const { setState } = useRematchDispatch(dispatch => dispatch.route);

  useEffect(() => {
    if (location) {
      setState(location);
    }
  }, [location, setState]);
};

export default usePageViews;
