import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useRematchDispatch, usePageData } from '../../hooks';

import People from './People';
import PeopleDetail from './PeopleDetail';

import pageStyles from '../page.module.css';

const Culture = () => {
  const { path } = useRouteMatch();
  const getCultureData = useRematchDispatch(dispatch => dispatch.page.getCultureData);
  const { isMobile } = useSelector(state => state.device);
  const { data, loaded } = usePageData({
    rematchDispatch: getCultureData,
    keys: ['blocks', 'people', 'top'],
    params: { isMobile },
  });

  useEffect(() => {
    console.log('path change', path);
  }, [path]);

  return (
    <div
      className={pageStyles.page}
    >
      {loaded && (
        <Switch>
          <Route exact path={path}>
            <People data={data} isMobile={isMobile} />
          </Route>
          <Route path={`${path}/:slug`}>
            <PeopleDetail people={data.people} />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default Culture;
