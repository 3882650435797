/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './JumpMenu.module.scss';

const JumpMenu = ({ count, active, onItemClick }) => {
  const onClick = useCallback((i) => {
    onItemClick(i);
  }, [active, onItemClick]);

  const items = [...Array(count).keys()];

  return (
    <div className={styles.jumpMenu}>
      { items.map((index) => {
        const isActive = index === active;
        const itemStyles = classNames(styles.jumpMenuItem, { [styles.active]: isActive });
        return (
          <span key={index} className={itemStyles} onClick={() => onClick(index)}>&nbsp;</span>
        );
      })}
    </div>
  );
};

JumpMenu.propTypes = {
  count: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default JumpMenu;
