import facebook from '../../../../assets/images/facebook1x.png';
import facebook2x from '../../../../assets/images/facebook2x.png';
import facebook3x from '../../../../assets/images/facebook3x.png';
import linkedin from '../../../../assets/images/linkedin1x.png';
import linkedin2x from '../../../../assets/images/linkedin2x.png';
import linkedin3x from '../../../../assets/images/linkedin3x.png';
import twitter from '../../../../assets/images/twitter1x.png';
import twitter2x from '../../../../assets/images/twitter2x.png';
import twitter3x from '../../../../assets/images/twitter3x.png';
import instagram from '../../../../assets/images/instagram1x.png';
import instagram2x from '../../../../assets/images/instagram2x.png';
import instagram3x from '../../../../assets/images/instagram3x.png';

export const MENU_LINKS = [
  { label: 'TATTOO', link: '/', external: false },
  { label: 'EXPERTISE', link: '/expertise', external: false },
  { label: 'PORTFOLIO', link: '/portfolio', external: false },
  { label: 'CULTURE', link: '/culture', external: false },
  { label: 'CONTACT', link: 'http://contact.tattooprojects.com/', external: true },
];

export const SOCIAL_LINKS = [
  {
    uri: 'https://www.facebook.com/tattooadvertising', srcSet: `${facebook} 1x, ${facebook2x} 2x, ${facebook3x} 3x`, src: facebook, alt: 'facebook',
  },
  {
    uri: 'https://www.linkedin.com/company/tattoo-projects-llc/', srcSet: `${linkedin} 1x, ${linkedin2x} 2x, ${linkedin3x} 3x`, src: linkedin, alt: 'linkedin',
  },
  {
    uri: 'https://twitter.com/TattooProjects/', srcSet: `${twitter} 1x, ${twitter2x} 2x, ${twitter3x} 3x`, src: twitter, alt: 'twitter',
  },
  {
    uri: 'https://www.instagram.com/tattooprojects/', srcSet: `${instagram} 1x, ${instagram2x} 2x, ${instagram3x} 3x`, src: instagram, alt: 'instagram',
  },
];
