/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { ReactComponent as Icon } from '../../../../../assets/images/tattoo-icon.svg';
import { MENU_LINKS } from '../constants';

import styles from './Desktop.module.scss';

const Desktop = ({ toggleState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsOpen(true);
    toggleState(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    toggleState(false);
  }, []);

  const transitions = useTransition(isOpen, null, {
    from: { position: 'absolute', transform: 'translate(0,-40px)', opacity: 0 },
    enter: { opacity: 1, transform: 'translate(0,0px)' },
    leave: { opacity: 0, transform: 'translate(0, -40px)' },
  });

  return (
    <nav
      onMouseLeave={closeMenu}
      className={styles.nav}
    >
      { transitions.map(({ item, key, props }) => item && (
        <animated.ul key={key} style={props} className={styles.ul}>

          { MENU_LINKS.map(menuLink => (
            <li className={styles.li} onClick={closeMenu}>
              { menuLink.external ? (
                <a className={styles.link} href={menuLink.link} target="_blank" rel="noopener noreferrer">{menuLink.label}</a>
              ) : (
                <Link className={styles.link} to={menuLink.link}>{menuLink.label}</Link>
              )}
            </li>
          )) }

        </animated.ul>
      )) }
      <Icon width={68} height={44} className={styles.icon} onMouseEnter={openMenu} />
    </nav>
  );
};

Desktop.propTypes = {
  toggleState: PropTypes.func.isRequired,
};

export default Desktop;
