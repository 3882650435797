import { useTransition } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { ROUTE_DELAY } from '../config/constants';

const useRouterAnimation = () => {
  const transitions = useTransition(useLocation(), current => current.pathname, {
    from: {
      width: '100%',
      height: '100%',
      opacity: 0,
    },
    enter: () => async (next) => {
      await new Promise(resolve => setTimeout(resolve, ROUTE_DELAY));
      await next({ opacity: 1 });
    },
    leave: { opacity: 0 },
  });

  return transitions;
};

export default useRouterAnimation;
