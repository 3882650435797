/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';

import { CMSImage } from '../../../components/image';

import pageStyles from '../../page.module.css';
import styles from './Landing.module.scss';

const calc = (x, y) => {
  const diff = { x: (3456 - window.innerWidth) / 2, y: (2304 - window.innerHeight) / 2 };
  const { innerWidth: width, innerHeight: height } = window;
  const deltaX = ((x - width / 2) / (width / 2)) * diff.x;
  const deltaY = ((y - height / 2) / (height / 2)) * diff.y;
  return [deltaX, deltaY];
};
const transform = (x, y) => `translate3d(${0 - x}px,${0 - y}px,0)`;

const PortfolioContainer = ({ data }) => {
  const { isMobile } = useSelector(state => state.device);
  const [springProps, set, stop] = useSpring(() => (
    {
      xy: [0, 0],
      config: {
        mass: 100, tension: 550, friction: 800, clamp: true,
      },
    }
  ));

  // TODO: justen replace this with a better hook
  const [displayMobile, setDisplayMobile] = useState(window.innerWidth <= 1023);

  useLayoutEffect(() => {
    const handleResize = () => {
      setDisplayMobile(window.innerWidth <= 1023);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseMove = useCallback(({ clientX, clientY }) => {
    if (displayMobile) {
      set({ xy: [0, 0], immediate: true });
    } else {
      set({
        xy: calc(clientX, clientY),
        immediate: false,
        config: {
          mass: 100, tension: 550, friction: 800, clamp: true,
        },
      });
    }
  }, [displayMobile, set, stop]);

  return (
    <div
      onMouseMove={e => handleMouseMove(e)}
      className={classNames(pageStyles.wrapper, { [styles.mobile]: displayMobile })}
    >
      <div className={styles.wrapper}>
        <animated.div style={{ transform: springProps.xy.interpolate(transform) }} className={styles.framesWrapper}>
          <div className={styles.background} />
          {data && data.map(({ image, url, rollOverImage }, i) => (
            <div key={`frame_${i}`} className={classNames(styles.frame, styles[`style${i}`])}>
              <Link to={`/portfolio${url}`}>
                <div className={styles.imageContainer}>
                  <CMSImage {...rollOverImage} className={styles.image} />
                  <CMSImage {...image} className={classNames(styles.image, styles.imageFade)} />
                </div>
              </Link>
              <div className={styles.frameForeground} />
            </div>
          ))}
        </animated.div>
      </div>
    </div>
  );
};

PortfolioContainer.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default PortfolioContainer;
