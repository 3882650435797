/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const MetaData = ({ data }) => (
  <>
    {data && (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.Title}</title>
        <meta property="description" content={data.OgDescription} />
        <meta property="og:title" content={data.OgTitle} />
        <meta property="og:description" content={data.OgDescription} />
        <meta property="og:image" content={data.OgImage.url} />
      </Helmet>
    )}
  </>
);

MetaData.propTypes = {
  data: PropTypes.shape({
    Title: PropTypes.string.isRequired,
    OgTitle: PropTypes.string.isRequired,
    OgDescription: PropTypes.string.isRequired,
    OgImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default MetaData;
