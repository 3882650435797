import React, { useEffect, useState } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { animated } from 'react-spring';
import { useRematchDispatch, usePageViews, useRouterAnimation } from './hooks';
import {
  Home,
  Expertise,
  Clientele,
  Featured,
  Culture,
  Portfolio,
  NotFound,
} from './pages';
import Header from './components/nav/Header';
// import { GlobalFooter } from './components/GlobalFooter';

import 'reset-css';
import './index.scss';

const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const detectMobile = useMobileDetect();
  const { device } = useRematchDispatch(dispatch => ({
    device: dispatch.device.setDeviceInfo,
  }));

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useEffect(() => {
    const isMobile = detectMobile.isMobile();
    const isAndroid = detectMobile.isAndroid();
    const isIOS = detectMobile.isIos();

    device({
      isMobile, isAndroid, isIOS, width, height,
    });
  }, [width, height]);

  usePageViews();

  const transitions = useRouterAnimation();

  return (
    <React.Fragment>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/clientele">
              <Clientele />
            </Route>
            <Route path="/expertise">
              <Expertise />
            </Route>
            <Route path="/featured">
              <Featured />
            </Route>
            <Route path="/culture">
              <Culture />
            </Route>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </animated.div>
      ))}
      {/* <GlobalFooter /> */}
      <Header />
    </React.Fragment>
  );
};

export default App;
