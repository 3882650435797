import { createModel } from '@rematch/core';

export const device = createModel()({
  state: {
    isMobile: true,
  },
  reducers: {
    setDeviceInfo: (state, payload) => ({ ...state, ...payload }),
  },
});
