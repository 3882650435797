import { getByTypeAndSlug } from '../../../../api/page';
import { parseCMSImage } from '../../../../utils';

export default dispatch => async () => {
  const { data: portfolioData } = await getByTypeAndSlug({ type: 'portfolio' });

  const data = portfolioData.items.map((item) => {
    const {
      casestudy_url: url,
      image: imageData,
      rollover_image: rollOverData,
    } = item;

    return {
      url,
      image: parseCMSImage(imageData),
      rollOverImage: parseCMSImage(rollOverData),
    };
  });

  dispatch.page.setState({
    data,
  });
};
