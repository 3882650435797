/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';

import { ReactComponent as Icon } from '../../../../../assets/images/tattoo-icon.svg';
import { MENU_LINKS, SOCIAL_LINKS } from '../constants';

import styles from './Mobile.module.scss';

const Mobile = ({ toggleState, openOverride }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    toggleState();
  };

  const menuTransition = useTransition(isOpen, null, {
    from: { transform: 'translate(0,-40px)', opacity: 0 },
    enter: { opacity: 1, transform: 'translate(0,0px)' },
    leave: { opacity: 0, transform: 'translate(0, -40px)' },
  });

  const socialTransition = useTransition(isOpen, null, {
    from: { transform: 'translate(0,40px,0)', opacity: 0 },
    enter: { opacity: 1, transform: 'translate(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate(0, 40px,0)' },
  });

  useEffect(() => {
    if (openOverride) {
      toggleOpen();
    }

    if (openOverride === false && isOpen === true) {
      toggleOpen();
    }
  }, [openOverride]);

  return (
    <nav
      onMouseLeave={toggleOpen}
      onClick={toggleOpen}
      className={styles.nav}
    >
      { menuTransition.map(({ item, key, props }) => item && (
        <animated.ul key={`${key}-menu`} style={props} className={styles.menu}>
          { MENU_LINKS.map(menuLink => (
            <li className={styles.li} key={key + menuLink.label}>
              { menuLink.external ? (
                <a className={styles.link} href={menuLink.link}>{menuLink.label}</a>
              ) : (
                <Link className={styles.link} to={menuLink.link}>{menuLink.label}</Link>
              )}
            </li>
          )) }
        </animated.ul>
      )) }
      { socialTransition.map(({ item, key, props }) => item && (
        <animated.ul style={props} className={styles.social} key={`${key}-social`}>
          {SOCIAL_LINKS.map(socialLink => (
            <li key={key + socialLink.uri}>
              <a href={socialLink.uri} target="_blank" rel="noopener noreferrer">
                <img
                  src={socialLink.src}
                  alt={socialLink.alt}
                  srcSet={socialLink.srcSet}
                  width={33}
                  height={33}
                />
              </a>
            </li>
          ))}
        </animated.ul>
      ))}
      <Icon width={68} height={44} className={styles.icon} />
    </nav>
  );
};

Mobile.propTypes = {
  toggleState: PropTypes.func.isRequired,
  openOverride: PropTypes.bool.isRequired,
};

export default Mobile;
