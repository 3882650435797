/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';
import frameLeft from '../../../assets/images/case-studies/frame-left.png';
import frameCenter from '../../../assets/images/case-studies/frame-center.png';
import frameRight from '../../../assets/images/case-studies/frame-right.png';

const ExperienceMoreBlock = ({ data }) => {
  const frameStyles = [
    {
      bgSrc: frameLeft,
      bgClassName: styles.cardFrameLeft,
      imageClassName: styles.cardImageSide,
    },
    {
      bgSrc: frameCenter,
      bgClassName: styles.cardFrameCenter,
      imageClassName: null,
    },
    {
      bgSrc: frameRight,
      bgClassName: styles.cardFrameRight,
      imageClassName: styles.cardImageSide,
    },
  ];

  return (
    <div className={styles.moreProjectsWrapper}>
      <p className={styles.sectionTitle}>{data.Headline}</p>
      <div className={styles.moreProjects}>
        { data.ExperienceMoreMenuItem.map((item, i) => {
          const { bgSrc, bgClassName, imageClassName } = frameStyles[i];

          return (
            <a className={styles.projectCard} href={item.case_studies.slug}>
              <img className={[styles.cardFrame, bgClassName].join(' ')} src={bgSrc} alt="" />
              <div className={[styles.cardImage, imageClassName].join(' ')}>
                <img className={styles.cardRollover} src={item.ImageHover.url} alt={item.ImageHover.alt} />
                <img src={item.ImageDefault.url} alt={item.ImageDefault.alt} />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

ExperienceMoreBlock.propTypes = {
  data: PropTypes.shape({
    Headline: PropTypes.string.isRequired,
    ExperienceMoreMenuItem: PropTypes.arrayOf({
      case_studies: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
      ImageDefault: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      ImageHover: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default ExperienceMoreBlock;
