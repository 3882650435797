export const cream = '#ffe8c2';
export const darkRed = '#761518';
export const red = '#991b1f';
export const darkBrown = '#131210';
export const brown = '#372116';
export const blueGrey = '#d1d3d4';
export const pink = '#efb7b3';
export const aqua = '#77ccce';
export const charcoal = '#1c1b19';
export const black = '#000000';
export const grey = '#242424';
