/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import { parseCMSImage, parseCMSImageRecursive } from '../../../../utils';
import { getByTypeAndSlug } from '../../../../api/page';

/**
 * Parses the portfolio data to be used in the LandingPage component.
 */
const parsePortfolioData = data => (
  data.items.map((item) => {
    const {
      casestudy_url: url,
      image: imageData,
      rollover_image: rollOverData,
    } = item;

    return {
      url,
      image: parseCMSImage(imageData),
      rollOverImage: parseCMSImage(rollOverData),
    };
  })
);

export default dispatch => async ({ slug }) => {
  const { data: caseStudiesData } = await getByTypeAndSlug({ type: 'case-studies' });
  const { data: portfolioData } = await getByTypeAndSlug({ type: 'portfolio' });

  /* eslint-disable no-param-reassign */
  const caseStudiesBySlug = caseStudiesData.reduce((result, item) => {
    result[item.slug] = item;
    return parseCMSImageRecursive(result);
  }, {});
  /* eslint-enable no-param-reassign */

  dispatch.page.setState({
    data: {
      MetaData: portfolioData.MetaData,
      slug,
      caseStudies: caseStudiesBySlug,
      portfolioMenu: parsePortfolioData(portfolioData),
    },
  });
};
