/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AboutSlide from './Slides/AboutSlide';
import HomeSlide from './Slides/HomeSlide';
import styles from './SlideContainer.module.scss';
import JumpMenu from './JumpMenu';
import ScrollTeaser from '../ScrollTeaser';
import useScrollPosition from '../../hooks/useScrollPosition';

/**
 * Helper method for retrieving the slide that is currently in the
 * browser's viewport.
 */
const getActiveSlideFromScrollPosition = () => {
  const slides = document.querySelectorAll('[data-slide]');
  const viewport = {
    bottom: window.pageYOffset + window.innerHeight,
  };

  let activeSlide = 0;

  for (let i = 0; i < slides.length; i++) {
    const el = slides[i];
    if (el.offsetTop < viewport.bottom) {
      activeSlide = i;
    }
  }

  return activeSlide;
};

/**
 * Helper method for determining the Y position of a slide.
 */
const getScrollPositionOfSlide = (slideId) => {
  const slide = document.querySelector(`[data-slide="${slideId}"]`);
  return slide ? slide.offsetTop : 0;
};

const isSlideExiting = (slideId) => {
  const slide = document.querySelector(`[data-slide="${slideId}"]`);

  if (slide) {
    const slideBounds = slide.getBoundingClientRect();
    const bounds = {
      top: slide.offsetTop,
      bottom: slide.offsetTop + slideBounds.height,
    };

    const viewport = {
      bottom: window.pageYOffset + window.innerHeight,
    };

    // console.log(slide, { viewport, bounds });

    return bounds.bottom < viewport.bottom;
  }

  return false;
};

/**
 * Helper for getting a Slide component
 */
const getSlideByType = type => ({
  about: AboutSlide,
  slide: HomeSlide,
}[type] || null);

const SlideContainer = ({ slides }) => {
  const [curSlideIndex, setCurSlideIndex] = useState(0);
  const [scrollTeaserAbsolute, setScrollTeaserAbsolute] = useState(false);
  const { y: scrollY, scrollTo, viewportHeight } = useScrollPosition();
  const container = useRef();

  const goToSlide = (index) => {
    scrollTo(getScrollPositionOfSlide(index));
  };

  useLayoutEffect(() => {
    setCurSlideIndex(getActiveSlideFromScrollPosition());

    const bounds = container.current.getBoundingClientRect();
    const isPastBottom = scrollY > bounds.height - viewportHeight;
    if (isPastBottom) {
      setScrollTeaserAbsolute(true);
    } else {
      setScrollTeaserAbsolute(false);
    }
  }, [scrollY, slides, viewportHeight]);

  const namesOfClasses = classNames(
    styles.slideShowContainer,
    { [styles.exiting]: scrollTeaserAbsolute },
  );

  return (
    <div ref={container} className={namesOfClasses}>
      { slides.map(({ id, type, data }, i) => {
        const SlideComponent = getSlideByType(type);
        const slideClassNames = classNames(
          styles.slide,
          { [styles.exiting]: isSlideExiting(i) },
        );

        // Note: This is a SUPER goofy way to force the first about
        // slide to be taller than all the other slides.
        const height = type === 'about' ? '195vh' : 'auto';

        return (
          <div key={`slide_${id}`} className={slideClassNames} data-slide={i} style={{ height }}>
            <div className={styles.contentWrapper}>
              <SlideComponent data={data} />
            </div>
          </div>
        );
      })}
      <div className={styles.ui}>
        <div className={styles.jumpMenuContainer}>
          <JumpMenu count={slides.length} active={curSlideIndex} onItemClick={goToSlide} />
        </div>
        <div className={styles.scrollTeaserContainer}>
          <ScrollTeaser />
        </div>
      </div>
    </div>
  );
};

SlideContainer.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.shape.isRequired,
    }),
  ).isRequired,
};

export default SlideContainer;
