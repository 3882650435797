/* eslint-disable react/prop-types */

import React from 'react';
import TextLayer from './Elements/TextLayer';
import BlockBackground from './Elements/BlockBackground';
import styles from '../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';

const ImageBlock = ({ data }) => {
  const textLayer = data.Text;
  return (
    <div className={styles.projectBlock}>
      <BlockBackground blockData={data} />
      <TextLayer {...textLayer} />
    </div>
  );
};

export default ImageBlock;
