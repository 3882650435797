import React from 'react';
import PropTypes from 'prop-types';
import styles from './BackButton.module.scss';

const BackButton = ({ url }) => (
  <a href={url} className={[styles.backButton, styles.hideBackButton].join(' ')}>
    Back
  </a>
);

BackButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BackButton;
