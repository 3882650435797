/* eslint-disable max-len */

import React from 'react';
import styles from './NotFound.module.scss';

const NotFound = () => (
  <div className={styles.errorPage}>
    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          Page Not Found
        </h3>
        <p className={styles.description}>
          <strong>This is somewhat embarrassing, isn’t it?</strong>
          <br />
          It looks like nothing was found at this location. May we recommend something else?
        </p>
        <p className={styles.links}>
          <a href="/portfolio" title="">View Portfolio</a>
          <a href="/culture" title="">About Us</a>
          <a href="/expertise" title="">Our Expertise</a>
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;
