import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useSpring, animated } from 'react-spring';

import { CMSImage } from '../../image';

import styles from './DetailTop.module.scss';

const fadeTiming = 3500;

const DetailTop = ({ data }) => {
  const {
    name, title, bio, altBio,
  } = data;

  const [reset, setReset] = useState(false);

  const animation = useSpring({
    from: {
      opacity: 0,
    },
    to: async (next) => {
      await new Promise((resolve) => {
        setTimeout(resolve, fadeTiming);
      });
      await next({
        opacity: 1,
      });
    },
    reset,
    reverse: reset,
    onRest: () => setTimeout(setReset(!reset), fadeTiming),
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.photoWrapper}>
        <CMSImage {...bio.photo} className={styles.photo} />
        <animated.div style={animation}>
          <CMSImage {...altBio.photo} className={[styles.photo, styles.altPhoto].join(' ')} />
        </animated.div>
      </div>
      <div className={styles.container}>
        <div>
          <h1 className={styles.name}>{name}</h1>
          <h6 className={styles.blurb}>{bio.blurb}</h6>
          <h4 className={styles.title}>{title}</h4>
          <ReactMarkdown className={styles.bio}>{bio.copy_block}</ReactMarkdown>
        </div>
        <div className={styles.icons}>
          {bio.icons && bio.icons.map(icon => (
            <CMSImage {...icon} className={classNames(styles.icon)} />
          ))}
        </div>
      </div>
    </div>
  );
};

DetailTop.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    bio: PropTypes.shape({
      blurb: PropTypes.string,
      copy_block: PropTypes.string,
      photo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      icons: PropTypes.arrayOf({
        url: PropTypes.string.isRequired,
      }),
    }),
    altBio: PropTypes.shape({
      blurb: PropTypes.string,
      copy_block: PropTypes.string,
      photo: PropTypes.shape(PropTypes.shape({
        url: PropTypes.string.isRequired,
      })),
      icons: PropTypes.arrayOf({
        url: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default DetailTop;
