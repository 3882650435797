import axios from 'axios';

import { CMS_URL } from '../config/constants';

const client = axios.create({
  baseURL: CMS_URL,
});

export {
  client,
};
