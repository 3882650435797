/* eslint-disable react/prop-types */
import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import NotFound from '../Errors/NotFound';
import Landing from './Landing/Landing';
import CaseStudyEntry from './CaseStudies/CaseStudyEntry';
import { useRematchDispatch, usePageData } from '../../hooks';
import MetaData from '../../components/MetaData';

const Portfolio = () => {
  const { path } = useRouteMatch();
  const { getCaseStudiesData } = useRematchDispatch(dispatch => dispatch.page);
  const { data, loaded } = usePageData({
    rematchDispatch: getCaseStudiesData,
    slug: 'test',
    keys: ['slug', 'caseStudies', 'portfolioMenu'],
  });

  return (
    <React.Fragment>
      {loaded && (
        <Switch>
          <Route exact path={path}>
            <>
              <MetaData data={data.MetaData} />
              <Landing data={data.portfolioMenu} />
            </>
          </Route>
          { Object.keys(data.caseStudies).map(slug => (
            <Route path={`${path}/${slug}`}>
              <>
                <MetaData data={data.caseStudies[slug].MetaData} />
                <CaseStudyEntry data={data.caseStudies[slug]} />
              </>
            </Route>
          ))}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      )}
    </React.Fragment>
  );
};

export default Portfolio;
