import React from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import Image from './Image';
import styles from '../../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';

const BlockBackground = ({ blockData }) => {
  const { Background, Text } = blockData;
  return (
    <div className={styles.projectBackground}>
      <Image src={Background.Image.url} alt={Background.Image.alt} />
      { Background.VideoId && (
        <div className={styles.backgroundVideo}>
          <Vimeo video={Background.VideoId} loop background />
        </div>
      )}
      { Text && Text.ShowFill && <div className={styles.fill} /> }
    </div>
  );
};

BlockBackground.propTypes = {
  blockData: PropTypes.shape({
    Text: PropTypes.shape({
      ShowFill: PropTypes.bool,
    }),
    Background: PropTypes.shape({
      Image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      VideoId: PropTypes.string,
    }),
  }).isRequired,
};

export default BlockBackground;
