import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';

const RelatedProjectsBlock = ({ data }) => (
  <div className={styles.relatedProjectsWrapper}>
    <div className={styles.relatedProjects}>
      {data.RelatedCaseStudyCard.map(cardObj => (
        <div className={styles.relatedProjectCard}>
          <div className={styles.relatedProjectCardImage}>
            <a href={`/portfolio/${cardObj.case_studies.slug}`}>
              <img src={cardObj.Image.url} alt={cardObj.label} />
            </a>
          </div>
          <div className={styles.relatedProjectCardContent}>
            <p>{cardObj.Label}</p>
            <a href={`/portfolio/${cardObj.case_studies.slug}`}>View Case Study</a>
          </div>
        </div>
      ))}
    </div>
  </div>
);

RelatedProjectsBlock.propTypes = {
  data: PropTypes.shape({
    RelatedCaseStudyCard: PropTypes.arrayOf({
      Label: PropTypes.string.isRequired,
      case_studies: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      Image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default RelatedProjectsBlock;
