import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const CardIndexContext = createContext([{}, () => {}]);

const CardIndexProvider = (props) => {
  const [state, set] = useState(null);

  return (
    <CardIndexContext.Provider value={[state, set]}>
      {props.children}
    </CardIndexContext.Provider>
  );
};

CardIndexProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CardIndexProvider, CardIndexContext };
