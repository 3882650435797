/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-useless-computed-key */

import React, { useRef, useState, useLayoutEffect } from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CaseStudyEntry.module.scss';
import ImageBlock from '../../../components/CaseStudies/Blocks/ImageBlock';
import RelatedProjectsBlock from '../../../components/CaseStudies/Blocks/RelatedProjectsBlock';
import VideoBlock from '../../../components/CaseStudies/Blocks/VideoBlock';
import ShareBlock from '../../../components/CaseStudies/Blocks/ShareBlock';
import ExperienceMoreBlock from '../../../components/CaseStudies/Blocks/ExperienceMoreBlock';
import GlobalFooter from '../../../components/GlobalFooter/GlobalFooter';
import useScrollPosition from '../../../hooks/useScrollPosition';

import './case-study-effects.css';

const blocksByType = {
  ['casestudies.image-slide']: ImageBlock,
  ['casestudies.video-slide']: VideoBlock,
  ['casestudies.related-case-studies-slide']: RelatedProjectsBlock,
  ['casestudies.share-slide']: ShareBlock,
  ['casestudies.experience-more-slide']: ExperienceMoreBlock,
};

const imageOrVideoBlocksFilter = block => (
  ['casestudies.image-slide', 'casestudies.video-slide'].indexOf(block.__component) !== -1
);

const experienceMoreBlocksFilter = block => (
  block.__component === 'casestudies.experience-more-slide'
);

const relatedCaseStudiesBlocksFilter = block => (
  block.__component === 'casestudies.related-case-studies-slide'
);

const isBottomAboveFold = (element) => {
  const bounds = element.getBoundingClientRect();
  const isPastBottom = window.pageYOffset > bounds.height - window.innerHeight;
  return isPastBottom;
};

const isTopAboveFold = (element, offsetYPercent = 0.25) => {
  const bounds = element.getBoundingClientRect();
  return bounds.y < window.innerHeight * offsetYPercent;
};

const renderBlock = (blockData, activeBlocksById) => {
  const ContentBlock = blocksByType[blockData.__component];
  const key = `${blockData.__component}.${blockData.id}`;
  const active = activeBlocksById[key];

  return (
    <div key={key} className={classNames('block', active ? 'block--active' : '')} data-case-study-block={key}>
      <ContentBlock data={blockData} projectData={blockData} active={active} />
    </div>
  );
};

const CaseStudyEntry = ({ data }) => {
  const blocks = data.Content || [];
  const contentBlocks = blocks.filter(imageOrVideoBlocksFilter);
  const experienceMoreBlocks = blocks.filter(experienceMoreBlocksFilter);
  const relatedCaseStudiesBlocks = blocks.filter(relatedCaseStudiesBlocksFilter);
  const otherBlocks = experienceMoreBlocks.concat(relatedCaseStudiesBlocks);

  const contentBlocksContainer = useRef();
  const [activeBlocksById, setActiveBlocksById] = useState([]);
  const [pinLogo, setPinLogo] = useState(false);
  const { y: scrollY } = useScrollPosition();

  useLayoutEffect(() => {
    const shouldPinLogo = isBottomAboveFold(contentBlocksContainer.current);
    if (shouldPinLogo !== pinLogo) setPinLogo(shouldPinLogo);

    // This is a very "non-react" way of gathering all the elements,
    // on scroll, comparing their positions to the bottom of the viewport,
    // and updating an array of states keyed by the data attribute.
    const divs = document.querySelectorAll('[data-case-study-block]');
    const activeBlocks = {};
    for (let i = 0; i < divs.length; i++) {
      activeBlocks[divs[i].getAttribute('data-case-study-block')] = isTopAboveFold(divs[i]);
    }

    setActiveBlocksById(activeBlocks);
  }, [scrollY, pinLogo, setPinLogo]);

  return (
    <React.Fragment>
      <div ref={contentBlocksContainer} className={styles.projectBlockWrapper}>
        {contentBlocks.map(block => renderBlock(block, activeBlocksById))}

        {data.Logo && (
          <div className={classNames(styles.projectLogo, { [styles.projectLogoPinned]: !pinLogo })}>
            <img src={data.Logo.url} alt={data.Logo.alt} />
          </div>
        )}
      </div>

      <div className={styles.projectBlockWrapper}>
        {otherBlocks.map(block => renderBlock(block, activeBlocksById))}
      </div>
      <GlobalFooter />
    </React.Fragment>
  );
};

// CaseStudyEntry.propTypes = {
//   data: PropTypes.shape({
//     MetaData: PropTypes.shape({
//       Title: PropTypes.string.isRequired,
//       OgTitle: PropTypes.string.isRequired,
//       OgDescription: PropTypes.string.isRequired,
//       OgImage: PropTypes.shape({
//         url: PropTypes.string.isRequired,
//       }).isRequired,
//     }),
//     Logo: PropTypes.shape,
//     Content: PropTypes.arrayOf().isRequired,
//   }).isRequired,
// };

export default CaseStudyEntry;
