/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import styles from './GlobalFooter.module.scss';
import iconFacebook from '../../assets/images/icons/icon-facebook.svg';
import iconTwitter from '../../assets/images/icons/icon-twitter.svg';
import iconInstagram from '../../assets/images/icons/icon-instagram.svg';
import iconLinkedin from '../../assets/images/icons/icon-linkedin.svg';
import featureCosmopolitan from '../../assets/images/footer/feature-cosmopolitan.png';
import featureAaa from '../../assets/images/footer/feature-aaa.png';
import featureCbj from '../../assets/images/footer/feature-cbj.png';

const socialProfiles = [
  {
    url: 'https://www.facebook.com/tattooadvertising',
    label: 'Tattoo Projects on Facebook',
    image: iconFacebook,
  },
  {
    url: 'https://www.linkedin.com/company/tattoo-projects-llc/',
    label: 'Tattoo Projects on LinkedIn',
    image: iconLinkedin,
  },
  {
    url: 'https://twitter.com/TattooProjects/',
    label: 'Tattoo Projects on Twitter',
    image: iconTwitter,
  },
  {
    url: 'https://www.instagram.com/tattooprojects/',
    label: 'Tattoo Projects on Instagram',
    image: iconInstagram,
  },
];

const GlobalFooter = () => (
  <footer>
    <div className={styles.footerWrapper}>
      <div className={styles.footerFeature}>
        <div className={styles.footerFeatureItem}>
          <p>
            &ldquo;Simply
            <br />
            Stunning&rdquo;
          </p>
          <img src={featureCosmopolitan} alt="" />
        </div>
        <div className={styles.footerFeatureItem}>
          <p>
            Best of
            <br />
            Show
          </p>
          <img src={featureAaa} alt="" />
        </div>
        <div className={styles.footerFeatureItem}>
          <p>
            &ldquo;Charlotte&apos;s Top
            <br />
            Women-Run Businesses.&rdquo;
          </p>
          <img src={featureCbj} alt="" />
        </div>
      </div>

      <div className={styles.footerContentWrapper}>
        <div className={styles.footerContent}>
          <p>
            Thank you for your interest in Tattoo Projects Advertising. Our favorite project is always the next project. Kindly get in touch by phone or email to discuss the potential of a partnership. We can&apos;t wait to hear from you.
          </p>
        </div>
        <div className={styles.footerContent}>
          <h6>TELEPHONE</h6>
          <a href="tel:704.900.7150" title="Call Us">704.900.7150</a>
        </div>
        <div className={styles.footerContent}>
          <h6>EMAIL</h6>
          <a href="mailto:hello@tattooprojects.com " title="Email Us">hello@tattooprojects.com</a>
        </div>
        <div className={styles.footerContent}>
          <h6>ADDRESS</h6>
          <a href="https://www.google.com/maps/dir//801+S+Cedar+St,+Charlotte,+NC+28208/@35.2271621,-80.865751,15.24z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8856a1d311481a79:0xc62b848b8b1c27b2!2m2!1d-80.8561341!2d35.2270366" title="Visit Us" target="_blank" rel="noopener noreferrer">
            801 S Cedar St
            <br />
            Charlotte, NC 28208
          </a>
        </div>

        <div className={styles.footerSocial}>
          <a className={styles.socialBtn} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/tattooadvertising" title="Tattoo Projects on Facebook">
            <img src={iconFacebook} alt="" />
          </a>
          <a className={styles.socialBtn} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tattoo-projects-llc/" title="Tattoo Projects on LinkedIn">
            <img src={iconLinkedin} alt="" />
          </a>
          <a className={styles.socialBtn} target="_blank" rel="noopener noreferrer" href="https://twitter.com/TattooProjects/" title="Tattoo Projects on Twitter">
            <img src={iconTwitter} alt="" />
          </a>
          <a className={styles.socialBtn} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/tattooprojects/" title="Tattoo Projects on Instagram">
            <img src={iconInstagram} alt="" />
          </a>
        </div>

        <div className={styles.footerLegal}>
          <p>&copy; TATTOO PROJECTS, LLC. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </div>
  </footer>
);

export default GlobalFooter;
