/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  red,
  darkRed,
  darkBrown,
  brown,
  cream,
  blueGrey,
  pink,
  aqua,
  charcoal,
  black,
  grey,
} from '../../colors';

import { CMSImage } from '../../image';
import BackgroundGraphic from './BackgroundGraphic';
import styles from './Detail.module.scss';
import GlobalFooter from '../../GlobalFooter/GlobalFooter';

const colors = {
  strategy: {
    backgroundColor: red,
    backgroundGraphicColor: darkRed,
    textColor: cream,
  },
  social: {
    backgroundColor: grey,
    backgroundGraphicColor: charcoal,
    textColor: blueGrey,
  },
  production: {
    backgroundColor: grey,
    backgroundGraphicColor: charcoal,
    textColor: cream,
  },
  digital: {
    backgroundColor: grey,
    backgroundGraphicColor: charcoal,
    textColor: pink,
  },
  creative: {
    backgroundColor: grey,
    backgroundGraphicColor: charcoal,
    textColor: aqua,
  },
};

const ExpertiseDetail = ({
  data,
}) => {
  const {
    name,
    slug,
    copyBlock,
    logos,
    expertiseItems,
    logoCopy,
  } = data;

  const {
    backgroundColor,
    backgroundGraphicColor,
    textColor,
  } = colors[slug];

  return (
    <>
      <div
        className={styles.wrapper}
        style={{ backgroundColor }}
      >
        <div className={styles.contentWrapper} style={{ backgroundColor }}>
          <div className={styles.backgroundGraphic}>
            <BackgroundGraphic color={backgroundGraphicColor} />
          </div>
          <div className={styles.content}>
            <div className={styles.intro}>
              <h1 className={styles.headline} style={{ color: textColor }}>
                {name}
              </h1>
              <div className={styles.copy} style={{ color: textColor }}>
                {copyBlock}
              </div>
            </div>
            <div className={styles.expertiseItems}>
              {expertiseItems.map(({ title, copyBlock: itemCopyBlock }) => (
                <div className={styles.itemWrapper}>
                  <h3 className={styles.title} style={{ color: textColor }}>{title}</h3>
                  <div className={styles.copyBlock} style={{ color: textColor }}>{itemCopyBlock}</div>
                </div>
              ))}
            </div>
            <div className={styles.logosWrapper}>
              <h4 className={styles.logoHeadline} style={{ color: textColor }}>
                {logoCopy}
              </h4>
              <div className={styles.logos}>
                {logos && logos.map(logo => (
                  <Link to={logo.url}>
                    <CMSImage {...logo.image} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalFooter />
    </>
  );
};

ExpertiseDetail.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ExpertiseDetail;
