import { useLayoutEffect, useState, useRef } from 'react';
import { useSpring, config } from 'react-spring';

/**
 * Hook for animating the scroll position.
 */
const useAnimateToScrollPosition = () => {
  const [, setY] = useSpring(() => ({
    immediate: false,
    config: config.slow,
    y: 0,
    onFrame: (props) => {
      window.scroll(0, props.y);
    },
  }));

  return targetY => setY({ y: targetY });
};

/**
 * Hook for monitoring the current scroll position change.
 */
const useScrollPosition = () => {
  const scrollTo = useAnimateToScrollPosition();
  const [scrollY, setScrollY] = useState(window.pageYOffset);
  const scrollData = useRef({
    y: scrollY,
    oldY: scrollY,
    delta: 0,
    scrollTo,
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  });

  useLayoutEffect(() => {
    const onScroll = () => {
      const update = { ...scrollData.current };
      update.y = window.pageYOffset;
      update.oldY = scrollData.current.y;
      update.delta = window.pageYOffset - scrollData.current.y;

      scrollData.current = update;
      setScrollY(window.pageYOffset);
    };

    const onResize = () => {
      const update = { ...scrollData.current };
      update.viewportWidth = window.innerWidth;
      update.viewportHeight = window.innerHeight;

      scrollData.current = update;
      setScrollY(window.pageYOffset);
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return { ...scrollData.current };
};

export default useScrollPosition;
