/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import { CMSImage } from '../../image';

import styles from './TopGrid.module.scss';

const TopGrid = ({ data }) => {
  const { copy, images } = data;

  const getClass = index => styles[`image${index}`];

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.content}>
        <ReactMarkdown className={styles.copy}>{copy}</ReactMarkdown>
        {images && images.map((image, index) => (
          <CMSImage {...image} className={classNames(styles.img, getClass(index))} />
        ))}
      </div>
    </div>
  );
};

TopGrid.propTypes = {
  data: PropTypes.shape({
    copy: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default TopGrid;
