import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import { CMSImage } from '../../image';

import styles from './BottomGrid.module.scss';

const BottomGrid = ({ data }) => (
  <div className={styles.wrapper}>
    <Masonry
      options={{
        columnWidth: 10,
      }}
      className={styles.masonry}
    >
      {data.map(block => (
        <div className={styles.block}>
          {block.image
            ? (
              <div
                className={styles[block.size]}
              >
                <CMSImage {...block.image} lazy />
              </div>
            )
            : (
              <ReactMarkdown
                className={classNames(styles[block.size], styles.copy)}
              >
                {block.copy}
              </ReactMarkdown>
            )}
        </div>
      ))}
    </Masonry>
  </div>
);

BottomGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    copy: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    size: PropTypes.string,
  })).isRequired,
};

export default BottomGrid;
