import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';
import { DetailTop } from '../../../components/People';
import GlobalFooter from '../../../components/GlobalFooter/GlobalFooter';
import BackButton from '../../../components/BackButton';
import MetaData from '../../../components/MetaData';

const PeopleDetail = ({ people }) => {
  const { slug } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (slug && people) {
      console.log({ slug, people });
      setData(people.find(item => item.slug === slug));
    }
  }, [slug, people]);

  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          <MetaData data={data.MetaData} />
          <BackButton url="/culture" />
          <DetailTop data={data} />
        </React.Fragment>
      )}
      <GlobalFooter />
    </React.Fragment>
  );
};

PeopleDetail.propTypes = {
  people: PropTypes.arrayOf().isRequired,
};

export default PeopleDetail;
