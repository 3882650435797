import React from 'react';
import { animated, useTransition } from 'react-spring';
import { ReactComponent as Image } from '../../assets/images/scroll-teaser.svg';
import styles from './ScrollTeaser.module.scss';

const ScrollTeaserContainer = () => {
  const transitions = useTransition(true, null, {
    from: { opacity: 0.25 },
    enter: { opacity: 1 },
    leave: { opacity: 0.25 },
  });

  return (
    <React.Fragment>
      { transitions.map(({ item, key, props }) => item && (
        <animated.div key={key} style={props} className={styles.scrollTeaser}>
          <Image />
        </animated.div>
      )) }
    </React.Fragment>
  );
};

export default ScrollTeaserContainer;
