import { createModel } from '@rematch/core';

import { getByTypeAndSlug } from '../../api/page';
import {
  getCaseStudiesData,
  getCultureData,
  getExpertiseData,
  getHomeData,
  getPortfolioData,
} from './effects/page';

export const page = createModel()({
  state: {
    type: null,
    slug: null,
    data: null,
  },
  reducers: {
    setState: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: dispatch => ({
    fetchData: async (payload) => {
      dispatch.page.setState({ loading: true });

      const { data } = await getByTypeAndSlug(payload);

      dispatch.page.setState({
        ...payload,
        data,
        loading: false,
      });
    },
    getCultureData: getCultureData(dispatch),
    getExpertiseData: getExpertiseData(dispatch),
    getPortfolioData: getPortfolioData(dispatch),
    getCaseStudiesData: getCaseStudiesData(dispatch),
    getHomeData: getHomeData(dispatch),
  }),
});
