export const CMS_URL = process.env.CMS_URL || process.env.REACT_APP_CMS_URL;

export const SHOW_SCROLL_TEASER_PATHS = [
  '/tattoo',
  '/expertise',
  '/portfolio',
  '/featured',
];

export const ROUTE_DELAY = 500;
