import { getByTypeAndSlug } from '../../../../api/page';
import { parseCMSImage } from '../../../../utils';

const parseBlocks = ({ blocks }) => blocks.map(block => ({
  ...block,
  image: parseCMSImage(block.image),
}));

const parseThumbnail = ({ small, large }, isMobile) => {
  const image = isMobile ? large : small;

  return parseCMSImage(image);
};

const parsePeople = ({ people: peopleData, culture_blocks: blocks }, isMobile) => {
  const people = peopleData.map((person) => {
    const {
      bio,
      alt_bio: altBio,
      name,
      slug,
      title,
      thumbnail: thumbData,
      alt_thumbnail: altThumbData,
      MetaData,
    } = person;

    return {
      altBio: {
        ...altBio,
        photo: parseCMSImage(altBio.photo),
      },
      bio: {
        ...bio,
        photo: parseCMSImage(bio.photo),
        icons: bio.icons.map(icon => (parseCMSImage(icon))),
      },
      name,
      thumbnail: parseThumbnail(thumbData, isMobile),
      altThumbnail: parseThumbnail(altThumbData, isMobile),
      title,
      slug,
      MetaData,
    };
  });

  blocks.forEach((block) => {
    const {
      image,
      position,
    } = block;

    people.splice(position, 0, { thumbnail: parseCMSImage(image) });
  });

  return people;
};

const parseTop = ({ copy, ...rest }) => {
  const images = [
    parseCMSImage(rest.image_1),
    parseCMSImage(rest.image_2),
    parseCMSImage(rest.image_3),
    parseCMSImage(rest.image_4),
    parseCMSImage(rest.image_5),
    parseCMSImage(rest.image_6),
  ];

  return {
    copy,
    images,
  };
};

const getCultureData = dispatch => async ({ isMobile }) => {
  const { data: peopleData } = await getByTypeAndSlug({ type: 'people' });
  const { data: blocksData } = await getByTypeAndSlug({ type: 'culture-bottom-grid' });
  const { data: topData } = await getByTypeAndSlug({ type: 'culture-top-blocks' });
  const { data: pageData } = await getByTypeAndSlug({ type: 'culture-page' });

  const people = parsePeople(peopleData, isMobile);
  const blocks = parseBlocks(blocksData);
  const top = parseTop(topData) || {};

  const data = {
    blocks,
    people,
    top,
    ...pageData,
  };

  dispatch.page.setState({
    data,
  });
};

export default getCultureData;
