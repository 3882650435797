import React from 'react';
import styles from './ContactButton.module.scss';

const ContactButton = () => (
  <a href="http://contact.tattooprojects.com/" className={styles.contactButton} title="Contact Us" target="_blank" rel="noopener noreferrer">
    <svg width="55px" height="20px" viewBox="0 0 55 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M23,2 L1,2 L12.0004442,11 L23,2 Z M16,9.15985373 L24,17 L24,3 L16,9.15985373 Z M8.64331969,10 L1,18 L23,18 L15.3558063,10 L12.5397084,12.316295 C12.2233125,12.5761006 11.7766875,12.5761006 11.4594176,12.316295 L8.64331969,10 Z M8,9.15985373 L0,3 L0,17 L8,9.15985373 Z" />
      <path d="M54.7953142,16.8064944 C53.6621209,19.5231434 51.0057656,19.9989038 50.1377183,19.9989038 C49.8831781,19.9989038 45.9133302,20.2094461 40.2073877,14.7949175 C35.6150585,10.4371808 35.0537647,5.75302192 35.0048147,4.86270531 C34.9574963,3.99523825 35.2055099,1.82045019 38.1971729,0.203844135 C38.5675615,0.00391052403 39.3026279,-0.0850395316 39.4927172,0.105101413 C39.5767481,0.189155135 42.0878849,4.32166047 42.1539674,4.45875781 C42.22005,4.59585514 42.2526833,4.73050431 42.2526833,4.86188925 C42.2526833,5.05121414 42.1180707,5.28705419 41.8496614,5.57022547 C41.5804362,5.85339675 41.2875518,6.11290242 40.9726399,6.34874247 C40.6552805,6.58539858 40.3640278,6.83511158 40.0939868,7.09869753 C39.8247616,7.36309953 39.690149,7.58098636 39.690149,7.75072592 C39.7089132,8.19139592 40.0703276,9.76964736 42.8205038,12.188436 C45.5714958,14.6064086 46.8972259,15.287815 47.0261277,15.3335141 C47.1525819,15.3816614 47.2586403,15.405327 47.3434871,15.405327 C47.5131805,15.405327 47.7301923,15.2706779 47.9945225,15.0021956 C48.2580369,14.7328973 49.1464801,13.5153424 49.4303903,13.246044 C49.7134846,12.9775618 49.9500765,12.8429126 50.1377183,12.8429126 C50.2698834,12.8429126 50.404496,12.8763709 50.5407402,12.9416553 C50.6778003,13.0077558 54.8148942,15.4298087 54.8940301,15.5097821 C55.1085944,15.7309332 54.9568493,16.4156038 54.7953142,16.8064944" />
    </svg>
  </a>
);

export default ContactButton;
