import { createModel } from '@rematch/core';

export const ui = createModel()({
  state: {
    reality: true,
    primaryNavOpen: false,
    primaryNavIntroComplete: false,
    userIsIdle: false,
    showScrollTeaser: false,
  },
  reducers: {
    toggle: (state, prop, force = null) => ({
      ...state,
      [prop]: force === null ? !state[prop] : force,
    }),
    set: (state, payload) => ({
      ...state,
      [payload.key]: payload.value,
    }),
  },
});
