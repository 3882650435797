import React from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import styles from './HomeSlide.module.scss';

const colors = {
  cream: styles.slideContentColorCream,
  gray: styles.slideContentColorGray,
  red: styles.slideContentColorRed,
};

const HomeSlideBackground = ({ data }) => {
  const desktopBg = data.Background.Image;
  const mobileBg = data.Background.MobileImage;
  const videoId = data.Background.VideoId;

  return (
    <div className={styles.projectBackground}>
      <div className={styles.slideBgDesktop} style={{ backgroundImage: `url(${desktopBg.url})` }} />
      <div className={styles.slideBgMobile} style={{ backgroundImage: `url(${mobileBg.url})` }} />
      { videoId && (
        <div className={styles.slideBgVideo}>
          <Vimeo video={videoId} loop background />
        </div>
      )}
      { Text && Text.ShowFill && <div className={styles.fill} /> }
    </div>
  );
};

HomeSlideBackground.propTypes = {
  data: PropTypes.shape({
    Background: PropTypes.shape({
      Image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      MobileImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      VideoId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const HomeSlide = ({ data }) => {
  const tags = data.TagsCsv ? data.TagsCsv.split('\n') : null;
  const color = data.TextColor;

  return (
    <div className={styles.slide}>
      <HomeSlideBackground data={data} />
      <div className={styles.slideContentWrapper}>
        <div className={[styles.slideContent, colors[color]].join(' ')}>
          {data.Caption && (
            <p className={styles.slideContentDescription}>{data.Caption}</p>
          )}
          {tags && (
            <div className={styles.slideContentTags}>
              {tags.map(tag => <span key={tag}>{tag}</span>)}
            </div>
          )}
          {data.Cta && (
            <p className={styles.slideContentLink}>
              <a href={data.Cta.Url} title={data.Cta.Label}>{data.Cta.Label}</a>
            </p>
          )}
        </div>
        {data.Logo && (
          <div className={styles.slideLogo}>
            <img src={data.Logo.url} alt={data.Logo.alt} />
          </div>
        )}
      </div>
    </div>
  );
};

HomeSlide.propTypes = {
  data: PropTypes.shape({
    TextColor: PropTypes.string.isRequired,
    Caption: PropTypes.string,
    Cta: PropTypes.shape({
      Label: PropTypes.string.isRequired,
      Url: PropTypes.string.isRequired,
    }),
    TagsCsv: PropTypes.string,
    Logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    Background: PropTypes.shape({
      Image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      MobileImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default HomeSlide;
