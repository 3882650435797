import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import pageStyles from '../page.module.css';
import styles from './Clientele.module.scss';

const Clientele = () => {
  const { isMobile } = useSelector(state => state.device);

  return (
    <div
      className={classNames(pageStyles.wrapper, styles.wrapper, { [styles.mobile]: isMobile })}
    />
  );
};

export default Clientele;
