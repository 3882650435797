import React from 'react';
import { useSelector } from 'react-redux';

import { useRematchDispatch } from '../../../../hooks';
import Desktop from './Desktop';
import Mobile from './Mobile';

const PrimaryNav = () => {
  const { isMobile } = useSelector(state => state.device);
  const { toggle } = useRematchDispatch(dispatch => dispatch.ui);

  const toggleState = (open = null) => toggle('primaryNavOpen', open);

  return (isMobile ? <Mobile toggleState={toggleState} /> : <Desktop toggleState={toggleState} />);
};

export default PrimaryNav;
