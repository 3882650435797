/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import parseCMSImage from './parseCMSImage';

/**
 * Recursively parse the object for any CMS images.
 */
const parseCMSImageRecursive = (obj) => {
  const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const keys = Object.keys(obj);

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      const hasMimeType = value.hasOwnProperty('mime');
      const isImage = hasMimeType && imageTypes.includes(value.mime);
      obj[key] = isImage ? parseCMSImage(value) : parseCMSImageRecursive(value);
    }
  }

  return obj;
};

export default parseCMSImageRecursive;
