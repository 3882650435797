import { getByTypeAndSlug } from '../../../../api/page';
import { parseCMSImage } from '../../../../utils';

export default dispatch => async () => {
  const { data: expertiseCategories } = await getByTypeAndSlug({ type: 'expertise-categories' });
  const { data: pageData } = await getByTypeAndSlug({ type: 'expertise-page' });

  const categories = expertiseCategories.map((category) => {
    const {
      slug,
      Name: name,
      card_text: cardText,
      copy_block: copyBlock,
      expertise_items: expertiseData,
      logos: logoData,
      logo_copy: logoCopy,
      MetaData,
    } = category;

    const expertiseItems = expertiseData.map(item => ({
      title: item.Title,
      copyBlock: item.copy,
    }));

    const logos = logoData.map(logo => ({
      url: logo.url,
      image: parseCMSImage(logo.image),
    }));

    return {
      slug,
      name,
      cardText,
      copyBlock,
      expertiseItems,
      logos,
      logoCopy,
      MetaData,
    };
  });

  dispatch.page.setState({
    data: {
      MetaData: pageData.MetaData,
      categories,
    },
  });
};
