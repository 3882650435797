/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';

const TextLayer = ({ Layout = 'center', TitleLineOne = '', TitleLineTwo = '', Description = '', Image = null, Color = 'cream', ImageCaption = 'Test Caption' }) => {
  const layouts = {
    Left: styles.projectContentLeft,
    Center: styles.projectContentCenter,
    Right: styles.projectContentRight,
  };

  const colors = {
    cream: styles.projectContentColorCream,
    gray: styles.projectContentColorGray,
    red: styles.projectContentColorRed,
  };

  if (!TitleLineOne && !TitleLineTwo && !Description && !Image) {
    return null;
  }

  return (
    <div className={[styles.projectContentWrapper, layouts[Layout]].join(' ')}>
      <div className={['textLayer', styles.projectContent, colors[Color]].join(' ')}>
        {(TitleLineOne || TitleLineTwo) && (
          <h1 className={styles.projectTitle}>
            <span dangerouslySetInnerHTML={{ __html: (TitleLineOne || '') }} />
            {TitleLineTwo && <span className={styles.projectTitle2} dangerouslySetInnerHTML={{ __html: TitleLineTwo }} />}
          </h1>
        )}
        {Description && (
          <p className={styles.projectDescription} dangerouslySetInnerHTML={{ __html: Description }} />
        )}
      </div>
      {Image && (
        <div className={[styles.projectImageContent, colors[Color]].join(' ')}>
          <img src={Image.url} alt={Image.alt} />
          { ImageCaption && (
            <p className={styles.imageCaption}>{ImageCaption}</p>
          )}
        </div>
      )}
    </div>
  );
};

TextLayer.propTypes = {
  Layout: PropTypes.string,
  TitleLineOne: PropTypes.string,
  TitleLineTwo: PropTypes.string,
  Description: PropTypes.string,
  Color: PropTypes.string,
  Image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  ImageCaption: PropTypes.string,
};

TextLayer.defaultProps = {
  Layout: 'center',
  TitleLineOne: '',
  TitleLineTwo: '',
  Description: '',
  Color: 'cream',
  Image: null,
  ImageCaption: '',
};

export default TextLayer;
