/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useRematchDispatch from './useRematchDispatch';
import { containsAllKeys } from '../utils';

const usePageData = ({
  rematchDispatch,
  keys,
  slug,
  type,
  isArray,
  params = {},
}) => {
  if (!keys) throw new Error('you must include keys to check data');
  const [data, setData] = useState();
  const [loaded, setLoaded] = useState(false);
  const { page } = useSelector(state => state);
  const { fetchData } = useRematchDispatch(dispatch => ({
    fetchData: dispatch.page.fetchData,
  }));
  const getData = rematchDispatch || fetchData;

  useEffect(() => {
    if (!page || !containsAllKeys(page.data, keys)) {
      getData({
        slug,
        type,
        isArray,
        ...params,
      });
    } else {
      setData(page.data);
      setLoaded(true);
    }
  }, [page]);

  return { data, loaded };
};

export default usePageData;
