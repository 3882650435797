import { createModel } from '@rematch/core';

export const route = createModel()({
  state: {
    pathname: '/',
  },
  reducers: {
    setState: (state, payload) => ({ ...state, ...payload }),
  },
});
