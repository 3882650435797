/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React from 'react';
import styles from '../../../pages/Portfolio/CaseStudies/CaseStudyEntry.module.scss';
import iconFacebook from '../../../assets/images/icons/icon-facebook.svg';
import iconTwitter from '../../../assets/images/icons/icon-twitter.svg';
import iconInstagram from '../../../assets/images/icons/icon-instagram.svg';
import iconLinkedin from '../../../assets/images/icons/icon-linkedin.svg';
import iconSave from '../../../assets/images/icons/icon-save.svg';

const ShareBlock = () => (
  <div className={styles.projectBlock}>
    <div className={[styles.projectContentWrapper, styles.projectContentCenter].join(' ')}>
      <div className={styles.projectContent}>
        <h1 className={styles.projectTitle}>
          Data and instincts.
          <span>How we rode to record sales.</span>
        </h1>
        <p className={styles.projectDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a felis quis sem bibendum tincidunt non ac est. Donec aliquam malesuada turpis, ac tempor erat imperdiet vel. Orci varius natoque penatibus et magnis dis parturient montes.
        </p>
      </div>
    </div>
    <div className={styles.shareControls}>
      <div className={styles.shareControlsGroup}>
        <p className={styles.shareTitle}>Share</p>
        <a className={styles.shareBtn} href="#0">
          <img src={iconFacebook} alt="" />
        </a>
        <a className={styles.shareBtn} href="#0">
          <img src={iconTwitter} alt="" />
        </a>
        <a className={styles.shareBtn} href="#0">
          <img src={iconInstagram} alt="" />
        </a>
        <a className={styles.shareBtn} href="#0">
          <img src={iconLinkedin} alt="" />
        </a>
      </div>
      <div className={styles.shareControlsGroup}>
        <p className={styles.shareTitle}>Save</p>
        <a className={styles.shareBtn} href="#0">
          <img src={iconSave} alt="" />
        </a>
      </div>
    </div>
    <div className={styles.blockFill} />
    <div className={styles.projectBackground}>
      <img src="https://via.placeholder.com/1920x1080/202020/333333" alt="" />
    </div>
  </div>
);

export default ShareBlock;
