/* eslint-disable max-len */
/* eslint-disable object-curly-newline */

import React, { useLayoutEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import classNames from 'classnames';
import tattooLogo from '../../../assets/images/home/tattoo-logo.png';
import styles from './AboutSlide.module.scss';
import useScrollPosition from '../../../hooks/useScrollPosition';

const AboutSlide = () => {
  const [showManifesto] = useState(false);
  const { y } = useScrollPosition();
  const el = useRef();

  const [fillAnimation, setFillOpacity] = useSpring(() => ({ opacity: 0 }));
  const [textAnimation, setTextOpacity] = useSpring(() => ({ opacity: 0 }));

  useLayoutEffect(() => {
    const viewport = {
      top: 0,
      height: window.innerHeight,
      width: window.innerWidth,
      bottom: window.innerHeight,
    };

    const { height: elHeight } = el.current.getBoundingClientRect();
    const overflow = elHeight - viewport.height;
    const percentY = Math.min(1, Math.max(0, y / overflow));

    setFillOpacity({ opacity: percentY * 0.75 });
    setTextOpacity({ opacity: percentY });
  }, [y]);

  return (
    <div ref={el} className={classNames(styles.about, { [styles.showManifesto]: showManifesto })}>
      <div className={styles.background}>
        <animated.div className={styles.fill} style={fillAnimation} />
        <div className={styles.logo}>
          <img src={tattooLogo} alt="" />
        </div>
        <animated.div className={styles.content} style={textAnimation}>
          <p>
            Nobody leaves a cushy job at a premier advertising agency to start their own. Nobody flies to Middle-of-Nowhere, Ohio, every week for years, to personally usher a client into the 21st century. Nobody rolls up to one of the longest-running motorcycle rallies donning nail polish. Nobody evolves a convenience store chain into one of the fastest growing cult brands on the Eastern seaboard. Nobody deep fries a snack icon and turns it into an ongoing partnership. Nobody has the experience to successfully balance art and science with style and grace. Nobody works this hard. Cares this much. Has this much fun at a job. And nobody really uses the word &#34;indelible&#34; either.
          </p>
          <p>
            <strong>Nobody Except Tattoo Projects</strong>
          </p>
        </animated.div>
      </div>
    </div>
  );
};

export default AboutSlide;
