import React from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';

import { useRematchDispatch } from '../../../hooks';
import PrimaryNavContainer from './PrimaryNav';
import ContactButton from '../../ContactButton';

import styles from './Header.module.scss';

const Header = () => {
  const { isMobile, primaryNavOpen } = useSelector(state => ({
    isMobile: state.device.isMobile,
    primaryNavOpen: state.ui.primaryNavOpen,
  }));
  const { toggle } = useRematchDispatch(dispatch => dispatch.ui);

  const darkenerTransition = useTransition(primaryNavOpen, null, {
    from: { position: 'fixed', opacity: 0 },
    enter: { opacity: 0.8 },
    leave: { opacity: 0 },
  });

  const menuTransition = useTransition(true, null, {
    from: { position: 'fixed', transform: 'translate(0,-40px)', opacity: 0 },
    enter: { opacity: 1, transform: 'translate(0,0px)' },
    leave: { opacity: 0, transform: 'translate(0, -40px)' },
    onRest: () => {
      toggle('primaryNavIntroComplete');
    },
  });

  return (
    <React.Fragment>
      <ContactButton />
      {
        darkenerTransition.map(({ item, key, props }) => item
          && <animated.div className={styles.darkener} key={`${key}-darkener`} style={props} />)
      }
      {menuTransition.map(({ item, key, props }) => item && (
        <animated.header
          key={`${key}-header`}
          className={styles.header}
          style={{
            ...props,
            padding: isMobile ? '35px 39px 0px' : '33px 33px 0',
            width: isMobile ? 'calc(100%)' : 'calc(100%)',
          }}
        >
          <div className={styles.bg} />
          <PrimaryNavContainer />
        </animated.header>
      ))}
    </React.Fragment>
  );
};

export default Header;
