/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React, { useCallback, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import Vimeo from '@u-wave/react-vimeo';
import styles from './Video.module.scss';

const Video = ({ vimeoId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showVideo = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeVideo = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modalTransition = useTransition(isOpen, null, {
    from: { transform: 'translateY(100px)', opacity: 0 },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(100px)' },
  });

  return (
    <div>
      { modalTransition.map(({ item, key, props }) => item && (
        <animated.div key={key} className={styles.videoModal} style={props} onClick={closeVideo}>
          {isOpen && <Vimeo video={vimeoId} autoplay />}
        </animated.div>
      ))}

      <div className={styles.buttonPlayVideoWrapper}>
        <div className={styles.buttonPlayVideo} onClick={showVideo} />
      </div>
    </div>
  );
};

export default Video;
