import React from 'react';
import PropTypes from 'prop-types';
import PreloadImage from 'react-preload-image';

import { generateRandomString } from '../../utils';

const CMSImage = ({
  url,
  alt,
  width,
  height,
  className,
  lazy,
}) => (
  !lazy
    ? (
      <img
        src={url}
        alt={alt}
        className={className}
        height={height}
        width={width}
        key={generateRandomString(11)}
      />
    )
    : <PreloadImage src={url} className={className} lazy key={generateRandomString(11)} />
);

CMSImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  lazy: PropTypes.bool,
};

CMSImage.defaultProps = {
  alt: '',
  width: null,
  height: null,
  className: '',
  lazy: false,
};

export default CMSImage;
