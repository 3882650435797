import React from 'react';
import PropTypes from 'prop-types';

import { BottomGrid, PeopleGrid, TopGrid } from '../../../components/People';
import GlobalFooter from '../../../components/GlobalFooter/GlobalFooter';
import MetaData from '../../../components/MetaData';

import styles from './People.module.scss';

const People = ({ data, isMobile }) => (
  <React.Fragment>
    {data && (
      <>
        <MetaData data={data.MetaData} />
        <div className={styles.wrapper}>
          <TopGrid data={data.top} />
          <PeopleGrid data={data.people} isMobile={isMobile} />
          <BottomGrid data={data.blocks} />
        </div>
      </>
    )}
    <GlobalFooter />
  </React.Fragment>
);

People.propTypes = {
  data: PropTypes.shape({
    MetaData: MetaData.propTypes.isRequired,
    top: PropTypes.shape().isRequired,
    people: PropTypes.arrayOf().isRequired,
    blocks: PropTypes.arrayOf().isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default People;
